@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.cloud-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: #87ceeb;
  /* Light blue sky background */
}

.clouds {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("./assets/images/Cloud.png");
  /* Adjust the path */
  background-repeat: repeat-x;
  animation: moveClouds 30s linear infinite;
}

@keyframes moveClouds {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-50%);
  }
}


/* #bgImg {
  background-image: url("./assets/images/bg-bg.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
 */

 #bgImg {
  width: 100%;
  height: 100%; 
  background: url("./assets/images/bg-bg.png") repeat-x; 
  background-position: center center;
  background-size: cover;
  animation: move 10s linear infinite;
}

@keyframes move {
  0% { background-position: left; }
  100% { background-position: right; }
}

#contactButton {
}

#contactButton:hover {
  scale: 1.10;
}

