@font-face {
    font-family: 'Fredoka';
    src: url('Fredoka-Light.eot');
    src: url('Fredoka-Light.eot?#iefix') format('embedded-opentype'),
        url('Fredoka-Light.woff2') format('woff2'),
        url('Fredoka-Light.woff') format('woff'),
        url('Fredoka-Light.ttf') format('truetype'),
        url('Fredoka-Light.svg#Fredoka-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Fredoka';
    src: url('Fredoka-Regular.eot');
    src: url('Fredoka-Regular.eot?#iefix') format('embedded-opentype'),
        url('Fredoka-Regular.woff2') format('woff2'),
        url('Fredoka-Regular.woff') format('woff'),
        url('Fredoka-Regular.ttf') format('truetype'),
        url('Fredoka-Regular.svg#Fredoka-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Fredoka';
    src: url('Fredoka-Medium.eot');
    src: url('Fredoka-Medium.eot?#iefix') format('embedded-opentype'),
        url('Fredoka-Medium.woff2') format('woff2'),
        url('Fredoka-Medium.woff') format('woff'),
        url('Fredoka-Medium.ttf') format('truetype'),
        url('Fredoka-Medium.svg#Fredoka-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Fredoka';
    src: url('Fredoka-SemiBold.eot');
    src: url('Fredoka-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('Fredoka-SemiBold.woff2') format('woff2'),
        url('Fredoka-SemiBold.woff') format('woff'),
        url('Fredoka-SemiBold.ttf') format('truetype'),
        url('Fredoka-SemiBold.svg#Fredoka-SemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Fredoka';
    src: url('Fredoka-Bold.eot');
    src: url('Fredoka-Bold.eot?#iefix') format('embedded-opentype'),
        url('Fredoka-Bold.woff2') format('woff2'),
        url('Fredoka-Bold.woff') format('woff'),
        url('Fredoka-Bold.ttf') format('truetype'),
        url('Fredoka-Bold.svg#Fredoka-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

body, h1, h2, h3, h4, h5, h6, p, span, li, ol, ul, a {
    font-family: 'Fredoka';
    font-weight: 400;
}